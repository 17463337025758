
interface UnreadMessagesIndicatorProps {
  count: number;
}

const UnreadMessagesIndicator = ({ count }: UnreadMessagesIndicatorProps) => {
  if (count < 1) return null

  return (
    <div className="absolute -top-2 -left-2">
      <div className="absolute bg-red-500 rounded-full h-6 w-6 z-50 animate-ping group-hover:animate-none" />
      <div className="absolute bg-red-500 text-white text-xs font-bold rounded-full h-6 w-6 flex items-center justify-center z-50">
        {count}
      </div>
    </div>
  )
}

export default UnreadMessagesIndicator