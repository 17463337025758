import Chat from "../chat"
import ToggleOpenButton from "../toggle-open-button"
import { CHAT_FRAME_WIDGET_ID, CHAT_WIDGET_ID } from "../../lib/constants"
import { useSpring, animated } from "@react-spring/web"
import useWidgetConfig from "../../hooks/use-widget-config"
import { useEffect, useRef, useState } from "preact/hooks"
import { ThemeContext } from "../../contexts/theme-context"
import { useAppContext } from "../../contexts/app-context"
import { sendMessage } from "../../lib/utils"
import PopupMessage from "../ui/popup-message"
import useChatWebSocket from "../../hooks/use-chat-websocket"

export default function WebChat() {
  const { data: widgetConfig, isLoading } = useWidgetConfig()
  const {
    inSparkfire,
    isSmallScreen,
    unreadMessagesCount,
    setUnreadMessagesCount,
    hasInboundMessages,
    hasContainer
  } = useAppContext()

  const chatWidgetRef = useRef<HTMLDivElement | null>(null)
  const urlParams = new URLSearchParams(window.location.search)

  const widgetId = urlParams.get('widget_id')
  const [isChatOpen, setIsChatOpen] = useState(() => {
    const savedState = localStorage.getItem('isChatOpen')
    const sfOpen = urlParams.get('_sfopen')

    if (sfOpen === 'true') return true
    if (hasContainer) return true

    if (savedState) {
      const parsedState = JSON.parse(savedState)
      return parsedState.widgetId === widgetId ? parsedState.isChatOpen : false
    }
    return false
  })
  const { isGeneratingResponse, setIsGeneratingResponse } = useChatWebSocket(hasInboundMessages, isChatOpen)

  const toggleIsChatOpen = () => {
    if (!isChatOpen) {
      setUnreadMessagesCount(0)
      localStorage.setItem('popupWasClosed', JSON.stringify(true))
    }
    
    setIsChatOpen(prevState => !prevState)
  }

  const animation = useSpring({
    config: { tension: 210, friction: 20, duration: 200 },
    from: { scale: 0, transformOrigin: 'bottom right' },
    to: { scale: isChatOpen ? 1 : 0 },
  })

  useEffect(() => {
    localStorage.setItem('isChatOpen', JSON.stringify({
      isChatOpen: isChatOpen,
      widgetId: widgetId
    }))

    sendMessage('resize', {
      isChatOpen: isChatOpen
    })

    if (!isChatOpen && unreadMessagesCount > 0) {
      sendMessage('resize', {
        width: 76,
        height: 76,
      })
    }

  }, [isChatOpen, widgetId, unreadMessagesCount])

  if (isLoading || !widgetConfig || !(widgetConfig.enabled || inSparkfire)) return <></>

  const popupDelay = widgetConfig.popup_delay * 1000
  const noPadding = (isSmallScreen && isChatOpen) || hasContainer

  return (
    <ThemeContext.Provider value={{
      primary_text_color: widgetConfig.primary_text_color,
      primary_background_color: widgetConfig.primary_background_color
    }}>
      <div ref={chatWidgetRef} id={CHAT_WIDGET_ID} className={`fixed bottom-0 right-0 p-2 flex flex-col gap-y-4 justify-end items-end ${noPadding && "!p-0"}`}>
        {isChatOpen &&
          <animated.div style={animation} className={`w-[100vw] ${!hasContainer && 'sm:w-[calc(100vw-1rem)]'}`} id={CHAT_FRAME_WIDGET_ID}>
            <Chat
              widgetConfig={widgetConfig}
              isChatOpen={isChatOpen}
              toggleIsChatOpen={toggleIsChatOpen}
              isGeneratingResponse={isGeneratingResponse}
              setIsGeneratingResponse={setIsGeneratingResponse}
            />
          </animated.div>
        }

        {!isChatOpen && widgetConfig.popup_message &&
          <PopupMessage
            displayTime={popupDelay} message={widgetConfig.popup_message}
            containerRef={chatWidgetRef}
          />
        }

        {!hasContainer &&
          <ToggleOpenButton
            isChatOpen={isChatOpen}
            toggleIsChatOpen={toggleIsChatOpen}
          />
        }

      </div>
    </ThemeContext.Provider >
  )
}
